var player;
var wnd;
export default function screen9Player(screen9) {
  if (player && wnd === window) return player;
  wnd = window;
  player = init(screen9);
  return player;
}
function init(screen9) {
  return function embed(item, onSuccess, onFail) {
    var _item$dataset = item.dataset,
      mediaId = _item$dataset.mediaId,
      autoPlay = _item$dataset.autoPlay,
      previewImageUrl = _item$dataset.previewImageUrl,
      token = _item$dataset.token;
    var ratio = 9 / 16;
    var options = {
      mediaid: mediaId,
      containerid: item.id,
      autoplay: autoPlay !== undefined,
      ajaxauth: token,
      previewImageUrl: previewImageUrl || "",
      ratio: ratio,
      muted: autoPlay !== undefined
    };
    screen9.api.embed(options, function (data) {
      if (onSuccess) onSuccess(data);
    }, onFail);
  };
}