import checkConsent from "./checkConsent";
import pushToDidomi from "./pushToDidomi";
import screen9Player from "../helpers/screen9Player";
import scriptLoader from "../helpers/scriptLoader";
var id = 0;
var players = [];
export var resetId = function resetId() {
  return id = 0;
};
export default function videoPlayer() {
  var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document;
  var screen9;
  var videos = context.getElementsByClassName("video");
  if (!videos.length) return;
  var intersectionThreshold = 0.1;
  var observer = new window.IntersectionObserver(viewPortUpdate, {
    threshold: intersectionThreshold
  });
  var isArticle = document.getElementsByClassName("article__wrapper").length > 0;
  for (var i = 0, len = videos.length; i < len; i++) {
    var video = videos[i];
    embed(video);
    observer.observe(video);
  }
  function viewPortUpdate(entries) {
    entries.forEach(function (_ref) {
      var target = _ref.target,
        intersectionRatio = _ref.intersectionRatio;
      var pause;
      if (isArticle && CHANNEL_MOBILE) return;
      if (intersectionRatio > intersectionThreshold) {
        target.setAttribute("data-visible", true);
        pause = false;
      } else {
        target.removeAttribute("data-visible");
        pause = true;
      }
      if (getPlayerDataAttrVal(target, "data-auto-play") === "autoplay") {
        playPause(getPlayerDataAttrVal(target, "data-media-id"), pause);
      }
    });
  }
  function getPlayerDataAttrVal(target, attributeName) {
    var noscript = target.getElementsByTagName("noscript")[0];
    if (noscript) {
      var mediaId = noscript.innerHTML.match(/(${attributeName}="\w*")/);
      return mediaId === null || mediaId === void 0 ? void 0 : mediaId[0].replaceAll("".concat(attributeName, "=\""), "").replaceAll('"', ""); // eslint-disable-line
    }
    var vp = target.getElementsByClassName("video__player")[0];
    return (vp === null || vp === void 0 ? void 0 : vp.getAttribute(attributeName)) || undefined;
  }
  function playPause(playerElementId, pause) {
    if (!playerElementId) return;
    var player = getPlayer(playerElementId);
    if (!player) return;

    // Screen9
    if (player.api) {
      if (pause) {
        player.api.pause();
      } else {
        player.api.resume();
      }
    }
  }
  function embed(container) {
    var playerElement = container.getElementsByClassName("video__player")[0];
    if (!playerElement) return;
    var provider = container.getAttribute("data-provider");
    var position = container.getAttribute("data-position");
    if (provider === "screen9") {
      pushToDidomi(function () {
        if (checkConsent(provider)) {
          return new Promise(initScreen9);
        }
      });
    }
    function initScreen9(resolve, reject) {
      scriptLoader("https://csp.screen9.com/js/screen9.js").then(function () {
        if (!screen9) {
          screen9 = screen9Player(window.screen9);
          playerElement.id = "video-".concat(++id);
          screen9(playerElement, function (_ref2) {
            var player = _ref2.player;
            players.push({
              playerElementId: playerElement.id,
              player: player
            });
            import("../helpers/screen9Tracking").then(function (n) {
              n["default"](player, playerElement, pushToDataLayer);
              resolve();
            });
          });
        }
      })["catch"](reject);
    }
    function pushToDataLayer(eventInfo) {
      window.dataLayer.push({
        event: "Video Playback",
        videoInfo: {
          position: position,
          videoVisible: container.getAttribute("data-visible") === "true"
        },
        eventInfo: eventInfo
      });
    }
  }
}
export function getPlayer(playerElementId) {
  var _ref3 = players.find(function (p) {
      return p.playerElementId === playerElementId;
    }) || {},
    player = _ref3.player;
  return player;
}
export function togglePaused(playerElementId) {
  var player = getPlayer(playerElementId);
  if (!player) return;
  if (player.paused) {
    player.api.resume();
  } else {
    player.api.pause();
  }
}
export function getVideoPlayerId(element) {
  if (!element) return null;
  var video = element.getElementsByClassName("video__player")[0];
  if (!video) return null;
  return video.id;
}