export default function forEach(elements, func) {
  if (!(elements !== null && elements !== void 0 && elements.length)) return;
  var promises = [];
  for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
    args[_key - 2] = arguments[_key];
  }
  for (var i = 0, len = elements.length; i < len; i++) {
    var res = func.apply(void 0, [elements[i]].concat(args));
    if (res && typeof res.then === "function") {
      promises.push(res);
    }
  }
  return Promise.all(promises);
}