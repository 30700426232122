export default function checkConsent(vendor) {
  if (vendor !== "screen9") return;
  var didomi = window.Didomi;
  if (didomi !== null && didomi !== void 0 && didomi.getUserConsentStatusForVendor("c:bn-screen9")) return true;
  var screen9Containers = document.getElementsByClassName("video__container--screen9");
  if (screen9Containers !== null && screen9Containers !== void 0 && screen9Containers.length) {
    for (var i = 0; i < screen9Containers.length; i++) {
      var wrapper = screen9Containers[i].closest(".video-player__wrapper");
      if (isSlideshow(wrapper)) {
        adjustSlideshow(wrapper);
      }
      wrapper.remove();
    }
  }
}
function isSlideshow(el) {
  return el.parentNode.classList.contains("slideshow__item");
}
function adjustSlideshow(el) {
  el.parentNode.remove();
  if (document.getElementsByClassName("slideshow__item").length === 1) {
    document.getElementsByClassName("slideshow__controls")[0].remove();
  }
}