import lazyLoad from "./plugins/lazyLoad";
import showMoreNewsItems from "./helpers/showMoreNewsItems";
export function init() {
  var _showMoreBtn$getAttri;
  var showMoreBtn = document.getElementsByClassName("js_show-more")[0];
  var tagId = (_showMoreBtn$getAttri = showMoreBtn === null || showMoreBtn === void 0 ? void 0 : showMoreBtn.getAttribute("id")) !== null && _showMoreBtn$getAttri !== void 0 ? _showMoreBtn$getAttri : null;
  var href = window.location.href;
  var isDiPlusPro = href.includes("ref=diPlusPro");
  var type = href.includes("bransch") ? "industry" : "tag";
  var endpoint = "/get-list-articles/?template=tagPage&id=".concat(tagId).concat(isDiPlusPro ? "&products=diPlusPro&type=".concat(type) : "");
  showMoreNewsItems(showMoreBtn, endpoint);
  lazyLoad();
}
init();