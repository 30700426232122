var initiatedScripts = [];
var loadedScripts = [];
var sources = []; // example data: [{src:"twitter.js", queue:[tweet1,tweet2,tweet3]}, {src:"arkku.js", queue:[arkku1]}]

export function reset() {
  initiatedScripts.splice(0, initiatedScripts.length);
  loadedScripts.splice(0, loadedScripts.length);
  sources.splice(0, sources.length);
}
export default function scriptLoader(src) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var queueItem = {};
  var promise = new Promise(function (resolve, reject) {
    queueItem.resolve = resolve;
    queueItem.reject = reject;
  });
  var source = sources.find(function (x) {
    return x.src === src;
  });
  if (source) {
    source.queue.push(queueItem);
  } else {
    sources.push({
      src: src,
      queue: [queueItem]
    });
  }
  if (loadedScripts.indexOf(src) > -1) {
    consumeQueue();
  } else if (initiatedScripts.indexOf(src) === -1) {
    initiatedScripts.push(src);
    loadScript().then(function () {
      loadedScripts.push(src);
      consumeQueue();
    })["catch"](function () {
      initiatedScripts.splice(initiatedScripts.indexOf(src), 1);
      queueItem.reject();
    });
  }
  return promise;
  function consumeQueue() {
    var queue = sources.find(function (x) {
      return x.src === src;
    }).queue;
    while (queue.length) {
      var item = queue.shift();
      item.resolve();
    }
  }
  function loadScript() {
    return new Promise(function (resolve, reject) {
      var script = document.createElement("script");
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      script.setAttribute("async", "async");
      Object.keys(options.attributes || []).forEach(function (key) {
        return script.setAttribute(key, options.attributes[key]);
      });
      document.body.appendChild(script);
    });
  }
}