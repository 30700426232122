import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
export default function screen9Tracking(player, el, trackingFn) {
  if (!window.screen9) return;
  var events = window.screen9.player.Event;
  var defaultData = {
    videoStartMethod: el.getAttribute("data-auto-play") ? "Autoplay" : "Manual Start",
    videoType: "Recorded Video",
    videoProvider: "Screen9",
    videoId: el.getAttribute("data-media-id")
  };
  var videoPercentsTracked = [0];
  var prerollPlayed = false,
    started = false,
    videoContentType,
    videoStatus;
  player.bind(events.AD_STARTED, function () {
    videoContentType = !prerollPlayed ? "Preroll Ad" : "Midroll Ad";
    videoStatus = "Start";
    prerollPlayed = true;
    trackingFn(getTrackingData());
  }).bind(events.AD_PAUSED, function () {
    videoStatus = "Pause";
    trackingFn(getTrackingData());
  }).bind(events.AD_RESUMED, function () {
    videoStatus = "Resume";
    trackingFn(getTrackingData());
  }).bind(events.AD_COMPLETE, function () {
    videoStatus = "Complete";
    trackingFn(getTrackingData());
  }).bind(events.PLAYER_PROGRESS, function () {
    var videoPercentToTrack = [10, 25, 50, 75, 90, 100];
    var calcVideoPercent = Math.floor(player.video.time / player.video.duration * 100);
    var thresholdReached = videoPercentToTrack.find(function (threshold) {
      return calcVideoPercent === threshold && !videoPercentsTracked.includes(calcVideoPercent);
    });
    if (thresholdReached) {
      videoPercentsTracked.push(calcVideoPercent);
      trackingFn(getTrackingData({
        videoStatus: "Progress",
        videoPercent: getLastVideoPercentTracked()
      }));
    }
  }).bind(events.PLAYER_PAUSE, function () {
    videoStatus = "Pause";
    trackingFn(getTrackingData());
  }).bind(events.PLAYER_RESUME, function () {
    if (!started) {
      started = true;
      videoContentType = "Content";
      videoStatus = "Start";
      trackingFn(getTrackingData());
    } else {
      videoStatus = "Resume";
      trackingFn(getTrackingData());
    }
  }).bind(events.PLAYER_FINISH, function () {
    videoStatus = "Complete";
    trackingFn(getTrackingData());
  }).bind(events.PLAYER_FULLSCREEN, onlyTrackIfPlaying).bind(events.PLAYER_FULLSCREEN_EXIT, onlyTrackIfPlaying).bind(events.PLAYER_MUTE, onlyTrackIfPlaying);
  function onlyTrackIfPlaying() {
    if (!player.playing) return;
    trackingFn(getTrackingData());
  }
  function getLastVideoPercentTracked() {
    return videoPercentsTracked[videoPercentsTracked.length - 1];
  }
  function getTrackingData() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return _objectSpread(_objectSpread(_objectSpread({}, defaultData), {}, {
      videoContentType: videoContentType,
      videoStatus: videoStatus,
      videoMuted: player.muted,
      fullscreenMode: player.isFullscreen
    }, !player.live && {
      videoDuration: player.video.duration,
      videoPercent: getLastVideoPercentTracked(),
      videoCurrentTime: player.video.time
    }), data);
  }
}