import throttle from "./throttle";
var innerHeight = window.innerHeight;
var innerWidth = document.documentElement.clientWidth;
window.addEventListener("resize", throttle(onResize));
export function getWindowInnerHeight() {
  return innerHeight;
}
export function getWindowInnerWidth() {
  return innerWidth;
}
function onResize() {
  innerHeight = window.innerHeight;
  innerWidth = document.documentElement.clientWidth;
}